<template>
  <div class="uk-container">
    <div v-if="!loading">
      <h2>{{ video.title }}</h2>
      <vimeo-player
        class="vimeo-player"
        v-if="! video.mega"
        ref="player"
        :video-id="video.video_url"
        player-width="auto"
        player-height="auto"
        :options="{ responsive: true }"
      />

      <mega-video :src="video.video_url" v-if="video.mega" />
      <video-comments :about="video.date" type="elite-videos" :id="video.id" role="trader" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      new_comment: {},
      new_reply: {},
      video: {},
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.$axios
        .get(`/api/v1/trader/elite-videos/${this.$route.params.id}`)
        .then((response) => {
          this.video = response.data.video;
          this.loading = false;
        });
    },
  },
};
</script>
